import React from 'react'
import './global-reset.scss'
import './global-fonts.scss'
import './layout.module.scss'
import '../helpers/analytics'
import * as s from './layout.module.scss'
import Seo from '../components/seo'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

export default function Layout({
  children,
  title = '',
  description = '',
  image = '',
  twitterImage = '',
  path
}: {
  children: JSX.Element | JSX.Element[]
  title?: string
  description?: string
  image?: string
  twitterImage?: string
  path: string
}) {
  // comment for redeploy
  return (
    <>
      <Seo
        title={title}
        description={description}
        path={path}
        image={image}
        twitterImage={twitterImage}
      />
      <div className={s.main}>
        <div className={s.isloation_container}>{children}</div>
      </div>
    </>
  )
}
