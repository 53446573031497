import Analytics, { AnalyticsInstance } from 'analytics'

const sendEvent = (payload: AnalyticsInstance) => {
  const customAnalyticsEndpoint = `${process.env.GATSBY_NOX_BASE_URL}/api/collect`
  // Send data to custom collection endpoint
  fetch(customAnalyticsEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      key: process.env.GATSBY_NOX_COLLECT_KEY,
      ...payload
    })
  }).catch(() => console.error('error'))
}

const analytics = Analytics({
  plugins: [
    {
      name: 'custom-analytics-plugin',
      track: ({ payload }) => {
        sendEvent(payload)
      },
      page: ({ payload }) => {
        sendEvent(payload)
      },
      identify: ({ payload }) => {
        sendEvent(payload)
      }
    }
  ]
})

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;(window as any).Analytics = analytics
}

export default analytics
