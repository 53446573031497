import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface SiteMetaData {
  title: string
  description: string
  image: string
  twitterImage: string
  siteUrl?: string
  path: string
}

export default function Seo(props: SiteMetaData) {
  const data = useStaticQuery(graphql`
    query GetSiteMetadata {
      site {
        siteMetadata {
          title
          description
          siteUrl
          image
          twitterImage
        }
      }
    }
  `)

  const defaults = data?.site?.siteMetadata as SiteMetaData
  const title = props.title || defaults.title
  const description = props.description || defaults.description
  const image = new URL(
    props.image || defaults.image,
    defaults.siteUrl
  ).toString()
  const twitterImage = new URL(
    props.twitterImage || defaults.twitterImage,
    defaults.siteUrl
  ).toString()
  const url = new URL(props.path || '/', defaults.siteUrl).toString()

  return (
    <Helmet defer={false} titleTemplate='Solana Spaces | %s'>
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={url} />

      <meta property='og:title' content={title} />
      <meta property='og:url' content={url} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />

      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={twitterImage} />
    </Helmet>
  )
}
